// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InStockDialog from "./../InStockDialog/InStockDialog";
import { Add, Remove, Delete } from "@mui/icons-material";
import { 
	Stack, 
	TextField, 
	Box, 
	Typography, 
	Paper, 
	List, 
	ListItem, 
	ListItemText, 
	Grid, 
	IconButton,
	Button } 
from "@mui/material";
import UserContext from "./../../Context/UserContext";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import DataTable from "./../DataTable/DataTable";
import Dialogs from "./../../Containers/Dialogs/Dialogs";
import StockCounter from "./../../Components/StockCounter/StockCounter";

const ReceiveStockDialog = (props) => {

	const context = useContext(UserContext); // user context for passing draft items into stock counter component
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [draftId, setDraftId] = useState(props.draftId);
	const hasDraft = draftId !== null;
	const [search, setSearch] = useState("");
	const [stock, setStock] = useState(null);
	const [items, setItems] = useState([]);
	const [matches, setMatches] = useState(null);
	const [drafts, setDrafts] = useState(null);
	const [addStock, setAddStock] = useState(false);
	const [confirmation, setConfirmation] = useState(false);

	useEffect(()=>{
		if(props.draftId !== null && props.draftId !== draftId){
			context.receiveStockItems = null;
			setStock(null);
		}
	});

	const itemMapper = (e, i) => { 
		return { 
			id: e.id, 
			stock_item_id: e.stock_item_id, 
			name: e.stock_item.name, 
			unit_quantity: e.unit_quantity,
			persisted: true, // item has been added to backend
			absolute_quantity: e.absolute_quantity
		};
	};

	const createDraft = () => {
		// grab items from context ...
		// need to be able to submit real quantities ...
		const localItems = items.map((e,i)=>{ const contextItem = context.receiveStockItems[`${e.stock_item_id}`]; e.unit_quantity = contextItem.actual_unit_quantity; e.absolute_quantity = contextItem.actual_absolute_quantity; return e; });
		const s = (response) => {
			const draft = response.data;
			const items = draft.items.map(itemMapper);
			const id = draft.id;
			toast.success("Draft created");
			setDraftId(id);
			setItems(items);
		};
		const e = (error) => {
			toast.error("Failed to create draft, please contact support.");
		};
		if(!impersonate){
			context.api.createReceiveStockDraft(context, s, e, localItems);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts`,
      "method": "POST",
			"data": JSON.stringify(localItems)
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	const updateDraft = () => {
		if(items.length === 0){
			toast.info("Please add some items before saving.");
			return;
		}
		// grab items from context ...
		// need to be able to submit real quantities ...
		const localItems = items.map((e,i)=>{ const contextItem = context.receiveStockItems[`${e.stock_item_id}`]; e.unit_quantity = contextItem.actual_unit_quantity; e.absolute_quantity = contextItem.actual_absolute_quantity; return e; });
		// TODO
		// update items with values from context
		const s = (response) => {
			const draft = response.data;
			const items = draft.items.map(itemMapper);
			toast.success("Draft updated");
			setItems(items);
		};
		const e = (error) => {
			toast.error("Failed to update draft, please contact support.");
		};
		if(!impersonate){
			context.api.saveReceiveStockDraftItems(context, s, e, draftId, localItems);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts/${draftId}/items`,
      "method": "POST",
			"data": JSON.stringify(localItems)
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	const saveDraft = (event) => {
		// create new draft
		if(!hasDraft){
			createDraft();
			return;
		}
		// update existing draft
		// as draft Id is not null
		updateDraft();
	};

	const confirmFinalise = () => {
		const s = (response) => {
			toast.success("Draft finalised");
			setConfirmation(false);
			props.onDraftFinalised();
			props.onCloseClicked();
		};
		const e = (error) => {
			toast.error("Failed to finalise draft, please contact support.");
			setConfirmation(false);
		};
		if(!impersonate){
			context.api.finaliseReceiveStockDraft(context, s, e, draftId);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts/${draftId}/finalise`,
      "method": "PUT",
			"data": JSON.stringify(items)
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	const finaliseDraft = (event) => {
		if(!hasDraft){
			toast.info("Please save your draft before finalising.");
			return;
		}
		if(items.length === 0){
			toast.info("Please add some items before finalising.");
			return;
		}
		// ask for confirmation before finalising
		setConfirmation(true);
	};

	const removeItem = (stockId) => {
		const copy = [...items];
		const i = copy.findIndex((x)=>{ return x.stock_item_id === stockId });
		if(i === -1 || i === undefined){
			toast.error("Failed to find item to remove.");
			return;
		}
		// cleanup context too ....
		if(context.receiveStockItems !== null){
			delete context.receiveStockItems[`${stockId}`];
		}
		const removed = copy.splice(i, 1)[0];
		if(removed.persisted === true){
			const s = (response) => {
				const draft = response.data;
				const items = draft.items.map(itemMapper);
				toast.success("Item removed");
				setItems(items);
			};
			const e = (error) => {
				toast.error("Failed to remove item from draft, please contact support.");
			};
			if(!impersonate){
				context.api.removeItemFromReceiveStockDraft(context, s, e, draftId, removed.id);
				return;
			}
			const payload = {
				"user_id": context.impersonatedUserId,
				"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts/${draftId}/items/${removed.id}`,
      	"method": "DELETE"
			};
			context.api.doImpersonate(context, s, e, payload);
		}
		setItems(copy);
	};

	const addToDraft = (id) => {
		const copy = [...items];
		const item = stock.find((x)=>{return x.id === id });
		if(item === undefined){
			toast.error(`Failed to find stock item with id ${id}`);
			return;
		}
		const existing = copy.find((x)=>{ return x.stock_item_id === id });
		if(existing !== undefined){
			toast.warn(`${existing.name} already exists in the draft`);
			return;
		}
		// maintain local copy as well as context copy
		// need to edit this via controllled component
		const i = { id: undefined, stock_item_id: item.id, name: item.name, unit_quantity: 1, absolute_quantity: item.standard_quantity, persisted: false };
		copy.push(i);
		// make sure that the context variable is not null
		if(context.receiveStockItems === null){
			context.receiveStockItems = {};
		}
		context.receiveStockItems[`${i.stock_item_id}`] = i;
		context.receiveStockItems[`${i.stock_item_id}`].actual_absolute_quantity = i.absolute_quantity;
		context.receiveStockItems[`${i.stock_item_id}`].actual_unit_quantity = i.unit_quantity;
		setItems(copy);
	};

	const doSearch = (term) => {
		const upper = term.toUpperCase();
		const filtered = stock.filter((x)=>{return x.name.toUpperCase().indexOf(upper) !== -1});	
		setSearch(term);
		setMatches(filtered);
	};

	useEffect(()=>{
		// use whether or not if the stock exists
		// as the condition to determine if this effect
		// needs to run
		if(stock !== null){
			return;
		}
		const s = async (response) => {
			const data = response.data;
			context.receiveStockItems = {};
			setStock(data);
			setMatches(data);
			if(draftId !== null){
				let r = null;
				if(!impersonate){
					r = await context.api.getReceiveStockDraftSync(context, draftId);
				}else{	
					const payload = {
						"user_id": context.impersonatedUserId,
						"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/receive-stock-drafts/${draftId}`,
      			"method": "GET"
					};
					r = await context.api.doImpersonateSync(context, payload);
				}
				const draft = r.data;
				const items = draft.items.map(itemMapper);
				// copy existing items into context
				items.forEach((i)=>{
					context.receiveStockItems[`${i.stock_item_id}`] = i;
					context.receiveStockItems[`${i.stock_item_id}`].actual_absolute_quantity = i.absolute_quantity;
					context.receiveStockItems[`${i.stock_item_id}`].actual_unit_quantity = i.unit_quantity;
				});
				setItems(items);
			}
		};
		const e = (error) => {
			toast.error("Failed to get stock items.");
		};
		if(!impersonate){
			context.api.getStockItems(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	});

	let content = (
		<Box sx={{textAlign:"center"}}>
			<LoadingSpinner message="Loading stock items ..."/>
		</Box>
	);

	if(stock !== null){
		// initial instructions
		let results = (
			<Paper elevation={3} sx={{padding:"25px", textAlign:"center"}}>
				<Typography sx={{color:"black", fontSize:"18px", fontWeight:"regular"}}>
					Please search for the stock items that you've received.
				</Typography>			
				<Typography sx={{color:"black", fontSize:"18px", fontWeight:"regular"}}>
					Once you've found the desired stock items, click the 
					<span style={{verticalAlign:"middle", backgroundColor:"#DEDEDE", borderRadius:"50%"}}>
						<Add sx={{color:"#C12393",position:"relative", top:"5px"}}/>
					</span> 
					button to add them to your draft.
				</Typography>			
			</Paper>
		);
		// RHS of screen
		// where the draft receive stock
		// will appear
		let right = null;
		// failed to find stock items
		// prompt user to try a different phrase
		if(matches !== null && matches.length === 0){
			results = (
				<Paper elevation={3} sx={{padding:"25px"}}>
					<Typography sx={{color:"black", fontSize:"18px", fontWeight:"regular"}}>No stock items exist containing the term <b>{search}</b>. Click <Link style={{textDecoration:"none"}}to={"/stock-items"} onClick={(event)=>{context.stockItemName = search; event.stopPropagation();setAddStock(true);}}><b>here</b></Link> to add one.</Typography>			
				</Paper>
			);	
		}

		// found results
		// show list of items to configure 
		// users must select ??
		if(matches !== null && matches.length > 0){
			const mapper = (e, i) => {
				return (
					<ListItem key={e.id}>
						<ListItemText primary={e.name} secondary={e.quantity_description}/>
						<IconButton onClick={(event)=>{addToDraft(e.id)}}>
							<Add sx={{color:"#C12393",fontSize:"2rem"}}/>
						</IconButton>
					</ListItem>
				);
			};
			const items = matches.map(mapper);
			results = (
				<Paper elevation={3} sx={{padding:"25px"}}>
					<List sx={{maxHeight:"400px", overflowY:"scroll"}}>
						{items}		
					</List>
				</Paper>
			);
		}
		// user had added items to their draft
		// need to include buttons to save draft
		// even when there's no items to display
			const getItemName = (params) => {
				return params.row.name;
			};
			const getQuantityValue = (params) => {
				const stockItem = stock.find((x)=>{ return x.id === params.row.stock_item_id });
				const standardQuantity = Number(stockItem.standard_quantity);
				return (
					<StockCounter 
          	id={stockItem.id}
          	standardQuantity={standardQuantity}
          	absoluteQuantity={params.row.absolute_quantity}
          	unitQuantity={params.row.unit_quantity}
          	units={stockItem.units} />
				);
			};
			const fields = [
				{ field: "name", headerName: "Name", valueGetter: getItemName },
				{ field: "quantity", headerName: "Quantity", valueGetter: getQuantityValue },
				{ field: "delete", headerName: "Remove", valueGetter: (params) => { return <IconButton onClick={(event)=>{event.stopPropagation();removeItem(params.row.stock_item_id);}}><Delete sx={{color:"#C12393", fontSize:"1.5rem"}}/></IconButton>} }
			];
			const sort = {
				"Name": { sorted: false, mode: "asc" }
			};

			let rightContent = (
				<Typography sx={{color:"black", fontWeight:"regular", fontSize:"18px", textAlign:"center", padding:"15px"}}>
					No items have been added to your draft, please use the <span style={{verticalAlign:"middle"}}>
					<Add sx={{color:"#C12393"}}/></span> button to add items to your receive stock draft.
				</Typography>
			);
			if(items.length > 0){
				rightContent = (
					<DataTable 
						rows={items} 
						columns={fields} 
						onRowClicked={(event)=>{}} 
						onHeaderClicked={(event)=>{}} 
						sort={sort} />
				);
			}
			right = (
				<Grid item lg={6} sm={12}>
					<Box sx={{padding:"15px"}}>
						<Stack spacing={2}>
							<Paper elevation={3}>
								{rightContent}
							</Paper>			
							<Grid container>
								<Grid item lg={6} sm={12}>
									<Box sx={{padding:"0px 5px"}}>
										<Button variant="contained" size="large" className="pg" onClick={saveDraft} fullWidth sx={{color:"white"}}>Save Draft</Button>
									</Box>
								</Grid>
								<Grid item lg={6} sm={12}>
									<Box sx={{padding:"0px 5px"}}>
										<Button variant="contained" size="large" className="pg" onClick={finaliseDraft} fullWidth sx={{color:"white"}}>Finalise</Button>
									</Box>
								</Grid>
							</Grid>
						</Stack>
					</Box>
				</Grid>
			);
		content = (
			<Grid container>
				<Grid item lg={6} sm={12}>
					<Box sx={{padding:"15px"}}>
						<Stack spacing={2}>
							<Grid container>
							<Grid item lg={9} sm={12}>
							<TextField 
								size={"large"}
								value={search} 
								fullWidth
								label={"Search for stock ...."} 
								onChange={(event)=>{doSearch(event.target.value);}} />
							</Grid>
							<Grid item lg={3} sm={12}>
								<Box sx={{ml:"10px"}}>
									<Button variant={"contained"} 
											fullWidth 
											size="large" 
											sx={{color:"white"}} 
											className="pg" 
											onClick={(event)=>{context.stockItemName = ""; setAddStock(true);}}>
										Add New
									</Button>	
								</Box>
							</Grid>
							</Grid>
							{results}
						</Stack>
					</Box>
				</Grid>
				{right}
			</Grid>
		);
	}
	return (
		<InStockDialog onCloseClicked={props.onCloseClicked} title={"Receive Stock"}>
			<Dialogs 
				impersonate={impersonate}
				addStock={addStock} 
				onStockCloseClicked={()=>{setAddStock(false);}} 
				onStockAdded={(event)=>{setStock(null);}} 
				confirmAction={confirmation} 
				confirmationMessage={"Are you sure that you want to finalise this receive stock draft?"} 
				onConfirmationClosed={()=>{setConfirmation(false);}} 
				onConfirmationAccepted={confirmFinalise} 
				onConfirmationRejected={()=>{setConfirmation(false);}}>
					{content}
			</Dialogs>
		</InStockDialog>
	);
};
export default ReceiveStockDialog;

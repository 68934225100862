// vim: ts=2
import React, { useState, useContext, useEffect } from "react";
// custom components
import AccountStatus from "./AccountStatus/AccountStatus";
import LocationStatus from "./LocationStatus/LocationStatus";
import CatalogItemSyncStatus from "./CatalogItemSyncStatus/CatalogItemSyncStatus";
import AddStockItemSetup from "./AddStockItemSetup/AddStockItemSetup";
import AddSalesItemSetup from "./AddSalesItemSetup/AddSalesItemSetup";
import OnBoardingStatus from "./OnBoardingStatus/OnBoardingStatus";
import MainLocationSetup from "./MainLocationSetup/MainLocationSetup";
import AddCategorySetup from "./AddCategorySetup/AddCategorySetup";
import AddStocktakingLocationSetup from "./AddStocktakingLocationSetup/AddStocktakingLocationSetup";
// context and api client
import UserContext from "./../../Context/UserContext";
import InStockClient from "./../../Api/InStockClient";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { Container, Stack } from "@mui/material";

const SetupView = (props) => {
	const context = useContext(UserContext);
	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [data, setData] = useState(null);
	const getData = () => {
		if(data !== null){
			return;
		}
		const s = async (response) => {
			const data = response.data;
			// copy setup data into context once setupis complete
			// setup is complete when on boarding message is displayed
			if(data.setupComplete){
				// this initialisation code was taken from the home page
				// as its also called during login ...
				context.setupComplete = data.setupComplete;
				context.locationCount = data.locationCount;
				let locations = null;
				if(!impersonate){
					locations = await context.api.getLocationsSync(context);
				}else{
					const payload = {
						"user_id": context.impersonatedUserId,
						"url": `/api/users/${context.impersonatedUserId}/locations`,
						"method": "GET"	
					};
					locations = await context.api.doImpersonateSync(context, payload);
				}
				locations = locations.data;
				locations = locations.filter((x)=>{ return x.active });
				context.locations = locations;
				const selected = data.locations.find((x)=>{ return x.name === data.mainLocation });
				context.selectedLocation = selected;
			}
			setData(data);		
		};
		const e = async (error) => {
			toast.error("Failed to get user setup status, please contact support.");
		};
		// TODO 
		// this method doesn't need to be called if  we're impersonating a user
		// setup information has already been fetched
		if(!impersonate){
			context.api.getUserSetupStatus(context, s, e);
			return;
		}
		// impersonating ..
		const setupPayload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/setup`,
			"method": "GET"	
		};
		context.api.doImpersonate(context, s, e, setupPayload);
	};
	useEffect(getData);
	let locationStatus = null;
	let catalogStatus = null;
	let onBoardingComplete = null;
	let mainLocationSelection = null;
	if(data !== null && data.squareComplete){
		locationStatus = <LocationStatus impersonate={impersonate} data={data} onLocationsSynced={()=>{setData(null);}} />;
		if(data.locationCount > 0){
			catalogStatus = <CatalogItemSyncStatus data={data} />;
			if(data.mainLocationSet){
				mainLocationSelection = <MainLocationSetup impersonate={impersonate} data={data} onMainLocationSet={()=>{setData(null);}} />;
				if(data.setupComplete){
					onBoardingComplete = <OnBoardingStatus impersonate={impersonate} data={data}/>;
				}
			}else{
				mainLocationSelection = <MainLocationSetup impersonate={impersonate} data={data} onMainLocationSet={()=>{context.logout("Onboarding complete, please log back in.");}} />;
			}
		}
	}
	return (
		<Container maxWidth="lg" className="instock-view">
			<Stack spacing={3}>
				<AccountStatus impersonate={impersonate} data={data} onSquareConnected={()=>{const copy = {...data}; copy.squareComplete = true; setData(copy);}}/>
				{locationStatus}
				{catalogStatus}
				{mainLocationSelection}
				{onBoardingComplete}
			</Stack>
		</Container>	
	);
}
export default SetupView;

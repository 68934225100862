// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel"; 
import UserContext from "./../../Context/UserContext";
import SearchContext from "./../../Context/SearchContext";
import ReportUsageContext from "./../../Context/ReportUsageContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";
import StockCategories from "./../Settings/StockCategories/StockCategories";
import StockCounter from "./../StockCounter/UnControlledStockCounter";
import DraftSelectionDialog from "./../DraftSelectionDialog/DraftSelectionDialog";
import { TablePagination, Box, Grid, Stack, IconButton, Typography, Paper, TextField, MenuItem, Chip, Button, Container } from "@mui/material";
import { units } from "./../../constants";
import DataTable from "./../DataTable/DataTable";
import { SearchField } from "./../Misc/Misc";

const ITEMS_PER_PAGE = 10;

const DEFAULT_CATEGORY = "Select a category";
 
const ReportUsageItem = (props) => {

	const item = props.stockItem;
	const context = props.context;
	const existing = context.items.find((x)=>{ return x.stock_item_id === item.id });

	const [absoluteQuantity, setAbsoluteQuantity] = useState(existing !== undefined ? existing.quantity : 0);
	const [unitQuantity, setUnitQuantity] = useState(existing !== undefined ? existing.units : 0);
	const [category, setCategory] = useState(existing !== undefined ? existing.category : DEFAULT_CATEGORY);
	const [description, setDescription] = useState(existing !== undefined ? existing.description : "");
	const usageTypes = [DEFAULT_CATEGORY, "Breakage", "Spillage", "Theft", "Evaporation", "Pouring", "Giveaway", "Expiry", "Other"];

	let categoryOptions = usageTypes.map((e, i)=>{ return <MenuItem value={e} key={`usage_type_${i}`}>{e}</MenuItem>});
	const updateItem = () => {
		let i = context.items.find((x)=>{ return x.stock_item_id === item.id });
		if(i === undefined){
			// create new draft item if one doesn't exist
			i = { stock_item_id: item.id, units: unitQuantity, quantity: absoluteQuantity, category: category, description: description };
			context.items.push(i);
			return;
		}
		i.units = Number(unitQuantity);
		i.quantity = Number(absoluteQuantity);
		i.category = category;
		i.description = description;
	};
	return (
		<Grid item xs={12}>
			<Box sx={{pt: "5px", pb:"15px", borderBottom:"1px solid #CDCDCD"}}>
			<Grid container spacing={2}>
				<Grid item xs={2}>
					<Box>
						<Typography sx={{color:"black", fontSize:"16px", fontWeight:"regular"}}>{item.name}</Typography>			
						<Typography sx={{color:"black", fontSize:"14px", fontWeight:"light"}}>{item.quantity_description}</Typography>
					</Box>
				</Grid>
				<Grid item xs={3}>
					<StockCounter absoluteQuantity={absoluteQuantity} 
						standardQuantity={item.standard_quantity} 
						unitQuantity={unitQuantity} 
						units={item.units}
						onQuantitiesChanged={(values)=>{setAbsoluteQuantity(values.absolute);setUnitQuantity(values.units);updateItem();}} 
						onAbsoluteQuantityChanged={(event)=>{setAbsoluteQuantity(event.target.value);}} 	
						onUnitQuantityChanged={(event)=>{setUnitQuantity(event.target.value);}} />
				</Grid>
				<Grid item xs={3}>
					<TextField size={"large"} label={"Usage category"} value={category} onBlur={(event)=>{updateItem();}} onChange={(event)=>{setCategory(event.target.value);}} select fullWidth>
						{categoryOptions}
					</TextField>
				</Grid>
				<Grid item xs={4} sx={{textAlign:"center"}}>
					<TextField size={"large"} label={"Description"} value={description} onBlur={(event)=>{updateItem();}} onChange={(event)=>{setDescription(event.target.value);}}/>
				</Grid>
			</Grid>
			</Box>
		</Grid>
	);
};

const ReportUsageDialog = (props) => {

	const context = useContext(UserContext);
	const search = useContext(SearchContext);
	const usage = useContext(ReportUsageContext);

	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [stockItems, setStockItems] = useState(null);
	const [draftId, setDraftId] = useState(null); // what draft was selected ...
	const [drafts, setDrafts] = useState(null); //  draft options
	const [page, setPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);
	
	const onPageChanged = (event, page) => {
		setPage(page);
	};	
	
	const getDrafts = () => {
		if(drafts !== null){
			return;
		}
		const s = (response) => {
			const data = response.data;
			setDrafts(data);
		};
		const e = (error) => {
			toast.error("Failed to get report usage drafts from backend");
		};
		context.api.getAllReportUsageDrafts(context, s, e);
	};

	const getStockItems = () => {	
		if(stockItems !== null){
			return;
		}
		const s = (response) => {	
			const data = response.data;
			search.original = [...data];
			setStockItems(data);
		};
		const e = (error) => {
			toast.error("Failed to retrieve stock items from backend.");
		};
		if(!impersonate){
			context.api.getStockItems(context, s, e);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-items`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	const finaliseDraft = () => {
		// reconstruct draft object
		const draft = {
			user_id: context.userId,
			location_id: context.selectedLocation.id,
			id: draftId,
			items: usage.items	
		};
		const s = (response) => {
			toast.success("Draft finalised");	
			props.onReportUsage();
		};
		const e = (error) => {
			toast.error("Failed to finalise draft");
		};
		context.api.finaliseReportUsageDraft(context, s, e, draft);
	};
		
	const saveDraft = () => {
		// reconstruct draft object
		const draft = {
			user_id: context.userId,
			location_id: context.selectedLocation.id,
			id: draftId,
			items: usage.items	
		};
		const s = (response) => {
			toast.success("Draft saved");
			const data = response.data;
			// TODO think about using response from server? not sure that we need it
		};
		const e = (error) => {
			toast.error("Failed to update report usage draft");
		};
		context.api.saveReportUsageDraft(context, s, e, draft);
	};
		
	const onNewDraft = () => {
		const s = (response) => {
			const data = response.data;
			usage.items = [];
			usage.id = data.id;
			setDraftId(data.id);
		};
		const e = (error) => {
			toast.error("Failed to get new report usage draft from backend");
		};
		context.api.getNewReportUsageDraft(context, s, e);
	};
	
	const onDeleteDraft = (event, id) => {
		const s = (response) => {
			const data = response.data; // TODO could return existing drafts ??
			setDrafts(null);
		};
		const e = (error) => {
			toast.error("Failed to delete draft");
		};
		context.api.deleteReportUsageDraft(context, s, e, id);
	};
	
	const onResumeDraft = (event, id) => {
		const selected = drafts.find((x)=>{ return x.id === id });
		if(selected === undefined){
			toast.info(`Failed to find draft with id ${id}`);
			return;
		}
		usage.items = selected.items;
		usage.id = id;
		usage.draft = selected;
		setDraftId(id);
	};
	
	{/* effects to load stock items an applicable drafts */}
	useEffect(getDrafts);
	useEffect(getStockItems);
	
	{/* load drafts first ... */}
	if(drafts === null){
		return (
			<InStockDialog title={"Report Usage"} onCloseClicked={props.onCloseClicked}>
				<LoadingSpinner message={"Loading drafts ..."} />
			</InStockDialog>
		);
	}
	
	{/* drafts are loaded, user needs to create or resume a draft */}
	if(draftId === null){
		return <DraftSelectionDialog 
				drafts={drafts}
				onNewDraftClicked={onNewDraft} 
				onDeleteDraftClicked={onDeleteDraft} 
				onResumeDraftClicked={onResumeDraft} 
        onCloseClicked={props.onCloseClicked}/>;
	}

	{/* load stock items */}
	if(stockItems === null){
		return (
			<InStockDialog title={"Report Usage"} onCloseClicked={props.onCloseClicked}>
				<LoadingSpinner message={"Loading stock items ..."} />
			</InStockDialog>
		);
	}
	
	const onQueryChanged = (query) => {
		const lc = query.toLowerCase();
		if(lc === ""){
			search.query = lc;
			setStockItems([...search.original]);				
			return;
		}
		const matches = search.original.filter((x)=>{ return x.name.toLowerCase().indexOf(lc) !== -1 });
		search.query = query;
		setStockItems(matches);
	};
			
	const offset = page * itemsPerPage;
	const paginated = stockItems.slice(offset, offset+itemsPerPage);
	{/* finally edit, save or finalise the draft */}
	return (
		<InStockDialog title={`Report Usage - Draft ${draftId}`} onCloseClicked={props.onCloseClicked}>
			<Paper elevation={3} sx={{padding:"25px"}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<SearchField onQueryChanged={onQueryChanged}/>
					</Grid>
					<Grid item xs={12}>
						<Box sx={{maxHeight:"500px", overflowY:"scroll"}}>
							<Grid container spacing={2}>
								{paginated.map((e,i)=>{ return <ReportUsageItem key={`report_usage_item_${e.id}`} stockItem={e} context={usage} />})}
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<TablePagination component={Box} count={search.original.length} 
							onPageChange={onPageChanged} page={page} 
							rowsPerPage={itemsPerPage} 
							onRowsPerPageChange={(event)=>{setItemsPerPage(event.target.value);}} />
					</Grid>
					<Grid item xs={12} sx={{mt:"15px"}}>
						<Grid container>	
							<Grid item xs={12} lg={7}/>
							<Grid item xs={12} lg={5} sx={{textAlign:"right"}}>
								<Grid container spacing={2}>
									<Grid item xs={12} lg={6}>
										<Button variant="outlined" size="large" fullWidth sx={{color:"black", backgroundColor:"white", border:"1px solid #E6E6E6"}} onClick={saveDraft}>Save Draft</Button>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Button variant="contained" size="large" fullWidth className="pg" onClick={finaliseDraft}>Finalise</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</InStockDialog>
	);
};

export default React.memo(ReportUsageDialog);

// vim: ts=2
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info"; 
import DownloadIcon from "@mui/icons-material/Download"; 
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserContext from "./../../Context/UserContext";
import SearchContext from "./../../Context/SearchContext";
import InStockDialog from "./../InStockDialog/InStockDialog";
import { 
	Accordion, AccordionSummary, AccordionDetails, 
	Box, Grid, Stack, IconButton, Typography, 
	Paper, TextField, MenuItem, 
	Button, Divider
} from "@mui/material";
import DataTable from "./../DataTable/DataTable";
import { DatePicker }  from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import Dialogs from "./../../Containers/Dialogs/Dialogs";
import { SearchField } from "./../Misc/Misc";

const EXPORT_HEADERS = [
	"stock_item_name",
	"standard_quantity",
	"stock_item_description",
	"units",
	"usage",
	"received",
	"shrinkage",
	"shrinkage_rate",
	"sell_through_rate",
	"stock_to_sales_ratio",
	"days_stock_on_hand",
	"starting_stock",
	"ending_stock",		
	"description"
];

const DEFAULT_AGGREGATION = "daily"; 

const RANGE_OPTIONS = [
	{ value: "", label:"Select a timeframe..." },
	{ value: "1M", label:"One Month" },
	{ value: "3M", label:"Current Quarter" },
	{ value: "YTD", label:"Year to Date" },
	{ value: "FYTD", label:"Financial Year to Date" },
	{ value: "FY", label:"Financial Year" },
	{ value: "MTD", label:"Month to Date" },
];

const AUS_QUARTERS = [
	{ from: 7, to: 10, label: "Q1" },
	{ from: 10, to: 1, label:"Q2" },
	{ from: 1, to: 4, label: "Q3" },
	{ from: 4, to: 7, label: "Q4" },
];

const DEFAULT_SORT_STATE = {
	"Name": { sorted: false, mode: "none" },
	"Usage": { sorted: false, mode: "none" },
	"Received": { sorted: false, mode: "none" },
	"Shrinkage": { sorted: false, mode: "none" },
	"Shrinkage Rate": { sorted: false, mode: "none" },
	"Sell-through Rate": { sorted: false, mode: "none" },
	"Stock-to-sales Ratio": { sorted: false, mode: "none" },
	"Days of stock on hand": { sorted: false, mode: "none" }
};

const StockReportInfo = (props) => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Stack spacing={2}>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							Usage
						</AccordionSummary>
						<AccordionDetails>
							Usage refers to the amount of stock used through sales during a given period. Usage here is the amount of the specific stock item that has been recorded as a result of stock items being assigned to a recipe for sales items. 
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							Received
						</AccordionSummary>
						<AccordionDetails>
							Received stock reported here is the amount of the specific stock item that has been recorded in the ‘Receive Stock’ feature of InStock. Accurately recording the amount of stock received into a business is critical to ensure accurate reporting, improve operational efficiencies and financial performance.
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							<Typography>Shrinkage</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography>Shrinkage is the loss of inventory that occurs due to various factors such as theft, damage, wastage, or administrative errors. Shrinkage can significantly affect profitability, as it often involves consumable goods like food and beverages, which are central to operations.<br/><br/>Shrinkage reported here is the combination of values reported in the ‘Report Usage’ feature of InStock and any variation that has been recorded when completing a stock take using the ‘Take Stock’ feature.<br/><br/>Example:
Suppose a business starts with 1,000 units of inventory, and after accounting for all sales and replenishments, expects to have 1,200 units.</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							Shrinkage rate
						</AccordionSummary>
						<AccordionDetails>
							Shrinkage rate is a critical metric that measures the loss of inventory due to factors such as theft, damage, errors, or spoilage. Monitoring the shrinkage rate regularly can help a business identify and address issues related to inventory management and loss prevention to improve profitability. In order for shrinkage rate to be calculated the stock item in question must have a transaction against it, otherwise shrinkage rate will be zero.<br/><br/>
							Example:<br/>
Suppose a business starts with 1,000 units of inventory, and after accounting for all sales and replenishments, expects to have 1,200 units. However, a physical count at the end of the period shows only 1,150 units are actually present.<br/>
							<ul>
								<li>Inventory Loss in Units = Expected Inventory (1,200 units) - Actual Inventory (1,150 units) = 50 units</li>
								<li>Shrinkage Rate = (50 units / 1,000 units) × 100 = 5%</li>
							</ul>
							This calculation means that 5% of the initial inventory was lost due to shrinkage. Tracking the shrinkage rate in units helps a business monitor its inventory accuracy, identify potential issues in handling or theft, and implement better controls to reduce losses.
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							Sell-through rate
						</AccordionSummary>
						<AccordionDetails>
							Sell-through rate measures the percentage of inventory sold during a specific period. It's calculated by dividing the number of units sold by the number of units that were available at the start of the period. A high sell-through rate indicates good sales performance, while a low rate may signal that stock levels are too high.<br/><br/>
							Example:<br/>
							Suppose a store starts the month with 200 units of a particular product and sells 150 units by the end of the month. The sell-through rate would be calculated as follows:
							<ul>
								<li>Sell-Through Rate = (150 Units Sold / 200 Units Initial Inventory) × 100 = 75%</li>
							</ul>
							This result means that 75% of the inventory available at the beginning of the period was sold. A high sell-through rate indicates good sales performance and effective inventory management, while a low sell-through rate may suggest overstocking or lower demand than anticipated, prompting a review of pricing or promotional strategies.
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							Stock to sales ratio
						</AccordionSummary>
						<AccordionDetails>
							This ratio compares the amount of inventory on hand to the number of sales, helping businesses to adjust their inventory levels to optimal quantities. It is particularly useful in seasonal businesses or those with fluctuating demand.<br/><br/>
							Example:<br/>
							Suppose a business has an ending inventory of 500 units for the month and during the same month, it sold 400 units. The stock-to-sales ratio would be calculated as follows:
							<ul>
								<li>Stock-to-Sales Ratio = 500 Units / 400 Units = 1.25</li>
							</ul>
							This result means that for every unit sold, there are 1.25 units available in inventory. A ratio greater than 1 indicates that the business has more stock on hand than it is selling, which could suggest overstocking or less efficient inventory turnover. Conversely, a ratio less than 1 indicates a lean inventory relative to sales, which might risk stockouts. This physical count-based calculation can help businesses adjust their inventory procurement and management strategies to better align with sales patterns.
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
							Days of stock on hand
						</AccordionSummary>
						<AccordionDetails>
							Days of stock on hand shows how long it will take to sell the current inventory. It focuses on the physical inventory count to determine how long the current stock will last under normal business conditions. This method is particularly useful for managing consumable goods in industries such as hospitality, retail, and manufacturing. This metric helps with planning restocks and managing storage space efficiently.<br/><br/>
							Example:<br/>
							Suppose a restaurant has 300 units of a particular ingredient in stock. Over the past month (30 days), they used 900 units of this ingredient.<br/>
							<ul>
								<li>Average Daily Usage = 900 units / 30 days = 30 units per day</li>
								<li>Current Stock on Hand = 300 units</li>
								<li>Days of Stock on Hand = 300 units / 30 units per day = 10 days</li>
							</ul>
							This result indicates that the current stock of this ingredient will last for approximately 10 days under normal usage conditions. This calculation is crucial for effective inventory management, helping ensure that the business maintains enough stock to meet customer demand without overstocking, which could lead to increased costs or waste.
						</AccordionDetails>
					</Accordion>
				</Stack>
			</Grid>
		</Grid>
	);
};

const StockReportsDialog = (props) => {

  const context = useContext(UserContext);
	const search = useContext(SearchContext);

	const impersonate = props.impersonate === undefined ? false : props.impersonate;
	const [dateFrom, setDateFrom] = useState(null); // moment object
	const [dateTo, setDateTo] = useState(null);  // moment object
	const [range, setRange] = useState("");
	const aggregation = DEFAULT_AGGREGATION;
	const [sort, setSort] = useState(null);
	const [metrics, setMetrics] = useState(null);
	const [download, setDownload] = useState(null);
	const [showInfo, setShowInfo] = useState(false);
	
	// make sure that the sort state is defined and set to something
	// initialise context too ...
	useEffect(()=>{
		if(sort !== null)
			return;
		if(context.stockReports.sort === null || context.stockReports.sort === undefined)
			context.stockReports.sort = DEFAULT_SORT_STATE;
		setSort(context.stockReports.sort);
	});
	
	const getStockReport = () => {	
		if(dateFrom === null || dateFrom === undefined || dateTo === null || dateTo === undefined){
			toast.info("Please select a date range");
			return;
		}
		const s = (response) => {	
			const data = response.data;  // { metrics: [], unit: "", standard_quantity: "" }
			setMetrics(data);
			search.original = [...data];
			if(context.stockReports.sort === null || context.stockReports.sort === undefined)
				context.stockReports.sort = DEFAULT_SORT_STATE;
			setSort(context.stockReports.sort);
		};
		const e = (error) => {
			toast.error("Failed to generate report.");
		};
		const startDate = dateFrom.format(moment.defaultFormatUtc);
		const endDate = dateTo.format(moment.defaultFormatUtc);
		if(!impersonate){
			context.api.getStockReport(context, s, e, startDate, endDate, aggregation);
			return;
		}
		const payload = {
			"user_id": context.impersonatedUserId,
			"url": `/api/users/${context.impersonatedUserId}/locations/${context.impersonatedLocationId}/stock-report`,
      "method": "GET"
		};
		context.api.doImpersonate(context, s, e, payload);
	};

	const doOneMonthRange = () => {
		const end = moment();
		const start = moment().subtract(1, "month");
		setDateFrom(start);
		setDateTo(end);
	};

	const doMonthToDate = () => {
		const end = moment();
		const startString = moment().format("YYYY-MM-01");
		const startDate = moment(startString);
		setDateFrom(startDate);
		setDateTo(end);
	}
	
	const doYearToDate = () => {
		const end = moment();
		const startString = moment().format("YYYY-01-01");
		const startDate = moment(startString);
		setDateFrom(startDate);
		setDateTo(end);
	}
	
	const doCurrentQuarter = () => 	{
		const month = Number(moment().format("MM"));
		let quarters = AUS_QUARTERS.filter((x)=>{ return month >= x.from && month < x.to});
		let quarter = quarters[0];
		// handle scenario when quarter rolls over the year
		let rollYear = false;
		if(quarter === undefined){
			quarters = AUS_QUARTERS.filter((x)=>{ return month >= x.from && month > x.to });
			quarter = quarters[0];
			rollYear = true;
		}
		const year = parseInt(moment().format("YYYY"));
		const start = moment(`${year}-${quarter.from}-01`);
		const end = rollYear ? moment(`${year+1}-${quarter.to}-01`).subtract(1,"day") : moment(`${year}-${quarter.to}-01`).subtract(1,"day");
		setDateFrom(start);
		setDateTo(end);
	};	

	const doFinancialYearToDate = () => {
		const month = Number(moment().format("MM")); //  current month
		let year = parseInt(moment().format("YYYY")); // current year
		const end = moment();
		if(month >= 7){
			const start = moment(`${year}-07-01`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}else{
			// financial year already started	previous year 
			const start = moment(`${year-1}-07-01`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}
	}	
	
	const doFinancialYear = () => {
		const month = Number(moment().format("MM")); //  current month
		let year = parseInt(moment().format("YYYY")); // current year
		if(month >= 7){
			const start = moment(`${year}-07-01`);
			const end = moment(`${year+1}-06-30`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}else{
			// financial year already started	previous year 
			const start = moment(`${year-1}-07-01`);
			const end = moment(`${year}-06-30`);
			setDateFrom(start);
			setDateTo(end);
			return;
		}
	}	

	const onRangeChanged = (event) => {
		setMetrics(null);
		const range = event.target.value;	
	 	switch(range){
			case "1M": 
				doOneMonthRange();
				break;
			case "3M":
				doCurrentQuarter();
				break;
			case "YTD":
				doYearToDate();
				break;
			case "FY":
				doFinancialYear();
				break;
			case "FYTD":
				doFinancialYearToDate();
				break;
			case "MTD":
				doMonthToDate();
				break;
			default:
				doOneMonthRange();
				break;
		}
		setRange(range);
	};

	const onSortClicked = (event, headerName) => {
    const copy = {...sort};
    const sortable = Object.keys(copy);
    const s = sortable.find((x)=>{ return x === headerName });
    if(s === undefined){
      toast.info(`Error, column ${headerName} is not sortable.`);
      return;
    }
    const others = sortable.filter((x)=>{ return x !== headerName });
    const sortState = copy[headerName];
    if(!sortState.sorted){
      // sort state has changed
      sortState.sorted = true;
      sortState.mode = "asc";
      // clear other headers
      others.forEach((x)=>{
        copy[x].sorted = false;
      });
      context.salesPage.sort = copy;
      setSort(copy);
      applySort(headerName, copy);
      return;
    }
    sortState.mode = sortState.mode === "asc" ? "desc" : "asc";
    context.salesPage.sort = copy;
    setSort(copy);
    applySort(headerName, copy);
  };


const applySort = (headerName, sortState) => {
    const s = sortState[headerName];
    const comparators = {
      "Name": ( a, b ) => { return s.mode === "asc" ? a.stockItemName.localeCompare(b.stockItemName) : b.stockItemName.localeCompare(a.stockItemName) },
      "Usage": ( a, b ) => { return s.mode === "asc" ? Number(a.usage) - Number(b.usage) : Number(b.usage) - Number(a.usage) },
      "Received": ( a, b ) => { return s.mode === "asc" ? Number(a.received) - Number(b.received) : Number(b.received) - Number(a.received) },
      "Shrinkage": ( a, b ) => { return s.mode === "asc" ? Number(a.shrinkage) - Number(b.shrinkage) : Number(b.shrinkage) - Number(a.shrinkage) },
      "Shrinkage Rate": ( a, b ) => { return s.mode === "asc" ? Number(a.shrinkageRate) - Number(b.shrinkageRate) : Number(b.shrinkageRate) - Number(a.shrinkageRate) },
      "Sell-through Rate": ( a, b ) => { return s.mode === "asc" ? Number(a.sellThroughRate) - Number(b.sellThroughRate) : Number(b.sellThroughRate) - Number(a.sellThroughRate) },
      "Stock-to-sales Ratio": ( a, b ) => { return s.mode === "asc" ? Number(a.stockToSalesRatio) - Number(b.stockToSalesRatio) : Number(b.stockToSalesRatio) - Number(a.stockToSalesRatio) },
      "Days of stock on hand": ( a, b ) => { return s.mode === "asc" ? Number(a.daysStockOnHand) - Number(b.daysStockOnHand) : Number(b.daysStockOnHand) - Number(a.daysStockOnHand) }
    };
    //const copy = [...metrics];
    metrics.sort(comparators[headerName]);
    //setMetrics(copy);
  };

	const doExport = () => {
		if(metrics === null){
			toast.info("Please run a report first.");
			return;
		}
		const records = metrics.map((e,i)=>{ return {
			"stock_item_name": e.stockItemName,
			"standard_quantity": e.standardQuantity,
			"stock_item_description": e.stockItemDescription,
			"units": e.units,
			"usage": e.usage,
			"received": e.received,
			"shrinkage": e.shrinkage,
			"shrinkage_rate": e.shrinkageRate,
			"sell_through_rate": e.sellThroughRate,
			"stock_to_sales_ratio": e.stockToSalesRatio,
			"days_stock_on_hand": e.daysStockOnHand,
			"starting_stock": e.startingStock,
			"ending_stock": e.endingStock,
			"notes": e.notes
		}});
		// copy each record for each note ...
		const result = [];
		records.forEach((x)=>{
			const duplicated = x.notes.map(function(e,i){
				const copy = {...x};
				copy["description"] = e;
				delete copy["notes"];
				return copy;
			});
			duplicated.forEach((y)=>{
				result.push(y);	
			});
		});
		const payload = {
			headers: EXPORT_HEADERS,
			records: result
		};
		const s = (response) => {
			const data = response.data;
			toast.success("Export generated, please click on the link to download it.");
			setDownload(data);
		};
		const e = (error) => {
			toast.error("Failed to generate export, please contact support.");
		};
		context.api.getDataExport(context, s, e, payload);
	};

	const onQueryChanged = (query) => {
		if(query === ""){
			search.query = query;
			setMetrics([...search.original]);
			return;
		}
		search.query = query;
		const lcQuery = query.toLowerCase();
		const results = search.original.filter((x)=>{ return x.stockItemName.toLowerCase().indexOf(lcQuery) !== -1 });
		setMetrics(results);
	};
	
	const getReport = () => {
		if(metrics.length === 0){
			return (
				<Grid container>	
					<Grid item xs={12}>
						<Box sx={{pt:"15px", textAlign:"center"}}>
							<Typography variant={"body2"}>
								<InfoIcon sx={{verticalAlign:"middle", mr:"5px"}}/>
								No results found, please make sure that stock items exist for this location and try another date range.
							</Typography>
						</Box>
					</Grid>
				</Grid>
			);
		}
		// debugging
		const debug = true;
		// table columns
		const fields = [
			{ field: "stockItemName", headerName: "Name", valueGetter: (params)=>{ return <span>{params.row.stockItemName}<br/><span>({params.row.stockItemDescription})</span></span>} },
			{ field: "usage", headerName: "Usage", valueGetter: (params)=>{ return <span>{`${Number(params.row.usage).toFixed(2)} ${params.row.units}`}<br/><span>{`(${Number(params.row.usage/Number(params.row.standardQuantity)).toFixed(2)} units)`}</span></span> }},
			{ field: "received", headerName: "Received", valueGetter: (params)=>{ return <span>{`${Number(params.row.received).toFixed(2)} ${params.row.units}`}<br/><span>{`(${Number(params.row.received/Number(params.row.standardQuantity)).toFixed(2)} units)`}</span></span>}},
			{ field: "shrinkage", headerName: "Shrinkage", valueGetter: (params)=>{ return <span>{`${Number(params.row.shrinkage).toFixed(2)} ${params.row.units}`}<br/><span>{`(${Number(params.row.shrinkage/Number(params.row.standardQuantity)).toFixed(2)} units)`}</span></span>}},
			{ field: "shrinkageRate", headerName: "Shrinkage Rate", valueGetter: (params)=>{ return `${Number(params.row.shrinkageRate).toFixed(2)}%`}},
			{ field: "sellThroughRate", headerName: "Sell-through Rate", valueGetter: (params)=>{ return `${Number(params.row.sellThroughRate).toFixed(2)}%`}},
			{ field: "stockToSalesRatio", headerName: "Stock-to-sales Ratio", valueGetter: (params)=>{ return `${Number(params.row.stockToSalesRatio).toFixed(2)}`}},
			{ field: "daysStockOnHand", headerName: "Days of stock on hand", valueGetter: (params)=>{ return `${Number(params.row.daysStockOnHand).toFixed(2)}`}},
		];
		if(debug){
			fields.push({field:"startingStock", headerName: "Starting Stock", valueGetter:(params)=>{ return <span>{`${Number(params.row.startingStock).toFixed(2)} ${params.row.units}`}<br/><span>{`(${Number(params.row.startingStock/Number(params.row.standardQuantity)).toFixed(2)} units)`}</span></span>}});
			fields.push({field:"endingStock", headerName:"Ending Stock", valueGetter:(params)=>{ return <span>{`${Number(params.row.endingStock).toFixed(2)} ${params.row.units}`}<br/><span>{`(${Number(params.row.endingStock/Number(params.row.standardQuantity)).toFixed(2)} units)`}</span></span>}});
		}
		const startDate = dateFrom.format("DD/MM/YYYY");
		const endDate = dateTo.format("DD/MM/YYYY");
		const daysInPeriod = metrics[0].daysInPeriod;
		return (
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<Divider/>
						<Grid container>
							<Grid item xs={6} lg={4} sx={{pt:"5px", pb:"5px"}}>
								<SearchField onQueryChanged={onQueryChanged} />
							</Grid>
							<Grid item xs={6} lg={8} sx={{pt:"5px", pb:"5px"}}>
								<Box sx={{pt:"15px", textAlign:"center"}}>
									<Typography variant={"body2"}>
										<InfoIcon sx={{verticalAlign:"middle", mr:"5px"}}/>
										{`Showing stock report for period ${startDate} to ${endDate} (${daysInPeriod} days inclusive)`}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider/>
						<DataTable rows={metrics} 
							columns={fields} 
							onRowClicked={()=>{}} 
							onHeaderClicked={()=>{}} 
							sort={sort} 
							onSortClicked={onSortClicked} 
							filters={[]} />
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const helper = (
		<Grid item xs={12}>
			<Box sx={{textAlign:"center", marginTop:"15px"}}>
				<Typography variant={"body2"} sx={{display:"inline", border:"1px solid #CDCDCD", padding:"15px", borderRadius:"5px"}}>
					<InfoIcon sx={{verticalAlign:"middle", mr:"5px"}}/>Please select a date range.
				</Typography>
			</Box>
		</Grid>
	);
	
	let fileName = null;
	if(metrics !== null && dateTo !== null && dateFrom !== null){
		const shortDateFrom = dateFrom.format("YYYYMMDD");
		const shortDateTo = dateTo.format("YYYYMMDD");
		fileName = `stock_report_${shortDateFrom}_${shortDateTo}.csv`;
	}

	return (
		<Dialogs
			fileName={fileName}
			download={download !== null}
			fileToDownload={download}
			onDownloadClosed={()=>{setDownload(null);}}
			infoDialog={showInfo}
			onInfoDialogClosed={()=>{setShowInfo(false);}}
			infoComponent={<StockReportInfo/>}
>
		<InStockDialog title={`Stock Reports`} onCloseClicked={props.onCloseClicked}>
			<Paper elevation={3} sx={{padding:"25px"}}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<DatePicker format={"DD/MM/YYYY"} value={dateFrom} slotProps={{field:{clearable:true}}} onChange={(value, ctx)=>{setDateFrom(value);setMetrics(null);}} label={"From"} />		
							</Grid>
							<Grid item xs={3}>
								<DatePicker format={"DD/MM/YYYY"} value={dateTo} slotProps={{field:{clearable:true}}} onChange={(value, ctx)=>{setDateTo(value);setMetrics(null);}} label={"To"} />		
							</Grid>
							<Grid item xs={3}>
								<TextField value={range} onChange={onRangeChanged} label={"Timeframe"} helperText={"Select a pre-defined timeframe, all timeframes are inclusive."} select fullWidth>
									{RANGE_OPTIONS.map((e,i)=>{ return <MenuItem value={e.value} key={`range_option_${i}`}>{e.label}</MenuItem>})}
								</TextField>
							</Grid>
							<Grid item xs={1} sx={{textAlign:"center"}}>
								<Button onClick={getStockReport} 
									size="large" 
									variant="contained" 
									className="pg"
									sx={{color:"white", fontWeight:"bold", mr:"15px"}}>
										Run
								</Button>
							</Grid>
							<Grid item xs={1} sx={{textAlign:"center"}}>
								<IconButton onClick={doExport}>
									<DownloadIcon sx={{color:"black", fontSize:"2rem"}} />	
								</IconButton>	
							</Grid>
							<Grid item xs={1} sx={{textAlign:"center"}}>
								<IconButton onClick={()=>{setShowInfo(true);}}>
									<InfoIcon sx={{color:"black", fontSize:"2rem"}} />	
								</IconButton>	
							</Grid>
						</Grid>
					</Grid>
					{metrics === null || dateTo === null || dateFrom === null ? helper : getReport()}
				</Grid>
			</Paper>
		</InStockDialog>
		</Dialogs>
	);
};

export default React.memo(StockReportsDialog);

import React from "react";
const context = React.createContext({
	userId: null, // id of useR
	token: null, // authorization client
	api: null, // instance of api client
	freeTrialAlert: false, // has the user seen the free trial alert
	selectedLocation: null, // location the user has selected
	locations: null, // list of available locations for this user
	selectedItem: null, // selected item (caching)
	receiveStockItems: null, // items from current receive stock draft
	salesPage: { sort: null, rowsPerPage: 10 }, // state from sales page, instance of TableStateHelper
	stockPage: { sort: null, rowsPerPage: 10 }, // state from sales page, instance of TableStateHelper
	stockReports: { sort: null, rowsPerPage: 10 }
});
export default context;

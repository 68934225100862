import React, {useContext} from "react";
import Authorization from "./../../Context/UserContext";
const UserIsLoggedIn = (props) => {
	const context = useContext(Authorization);
	if(context.token === null || context.token === undefined){
		document.location = "/";
		return;
	}
	context.logout = (message) => {
		context.token = null;
		if(message === null || message === undefined){
			document.location = "/";
		}else{
			document.location = `/?message=${message}`;
		}
	};
	return (
		<>
			{props.children}
		</>
	);
};
export default UserIsLoggedIn;

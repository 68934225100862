// vim: ts=2

const validator = require("validate.js");
class FormHelper {
	validateSingle = (rules, field, value) => {
		let data = { };
		data[field] = value;
		let constraints = {};
		constraints[field] = rules[field];
		return validator.validate(data, constraints);
	}
	validateAll = (rules, data) => {
		return validator.validate(data, rules);
	};
	isPasswordValid = (password) => {
		const letters = "abcdefghijklmnopqrstuvqxyz";
		const upper = letters.toUpperCase();
		const digits = "0123456789";
		let lettersOk = false;
		let upperOk = false;
		let digitOk = false;
		// check letters
		for(let i = 0; i < letters.length; i++){
			if(password.indexOf(letters[i]) !== -1){
				lettersOk = true;
				break;
			}
		}
		// upper case 
		for(let i = 0; i < upper.length; i++){
			if(password.indexOf(upper[i]) !== -1){
				upperOk = true;
				break;
			}
		}
		// digits
		for(let i = 0; i < digits.length; i++){
			if(password.indexOf(digits[i]) !== -1){
				digitOk = true;
				break;
			}
		}
		return lettersOk && upperOk && digitOk;
	}
}
export default FormHelper;
